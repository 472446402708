<template>
  <div class="warehouse">
    <category-title :category="category" />

    <router-view></router-view>
    <faq-bar v-if="isAuthenticated" />
    <be-customer-card-horizontal card="registrationHome" />
    <newsletter-horizontal />
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import category from "~/mixins/category.js";
import BeCustomerCardHorizontal from "@/components/cards/BeCustomerCardHorizontal.vue";
import NewsletterHorizontal from "@/components/cards/NewsletterHorizontal.vue";
import FaqBar from "@/components/registration/FaqBar.vue";
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  components: {
    CategoryTitle,
    BeCustomerCardHorizontal,
    NewsletterHorizontal,
    FaqBar
  },
  mixins: [category],
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  }
};
</script>
