import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      category: ({ category }) => category.category
      // ,
      // pager: ({ category }) => category.pager
    }),
    ...mapGetters({
      breadcrumb: "category/categoryPath",
      promoFilter: "category/promoFilter"
    })
  },
  methods: {
    //deprecated -> use proposal mapper in ebsn.js
    getProposals(name) {
      return this._.get(this.category, "proposals." + name, []);
    },
    ...mapActions({
      setCategory: "category/setCategory",
      resetFilters: "category/resetFilters"
    })
  },
  metaInfo() {
    return {
      title: this._.get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this._.split(this.category.name, "@")[0]
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this._.get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.category.description
          )
        }
      ]
    };
  }
};
