<template>
  <div v-if="category" class="category-title">
    <v-img
      v-if="headerImage && !$vuetify.breakpoint.xs"
      dark
      :src="headerImage"
      :height="height"
      class="elevation-0 rounded-0"
      @load="loaded = true"
    >
      <div class="layer"></div>
      <h1 class="pt-16 text-center main-category-title">
        {{ title }}
      </h1>
    </v-img>
  </div>
</template>

<script>
export default {
  name: "CategoryTitle",
  props: {
    count: { type: Number, required: false },
    category: { type: Object, required: true },
    selectedCategory: { type: Object, required: false },
    height: { type: String, default: "170px" }
    // textColor: {type: String, default: '#636363'}
  },
  data: function() {
    return { loaded: false };
  },
  computed: {
    title() {
      let c = this.selectedCategory ? this.selectedCategory : this.category;
      return this._.get(c, "metaData.category_info.TITLE", c.name);
    },
    subtitle() {
      return this._.get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    },
    headerImage() {
      return this._.get(this.category, "metaData.category_info.HEADER_IMAGE");
    }
  }
};
</script>
